import React, { useState, useEffect, useCallback } from "react";
import {
  Radio,
  Button,
  Modal,
  Checkbox,
  DatePicker,
  message,

  Spin,
  Select,
  Icon,
  Input,
} from "antd";
import moment from "moment";
import { useHistory, Link, useLocation } from "react-router-dom";
import "./SignupPage.scss";
import header_logo from "../../../Assets/header_logo.png";
import AutoComplete from "../../../Common/AutoComplete/AutoComplete";
import CustomInput from "../../../Common/custom-input";
import S3 from "react-aws-s3";
import { Doctor_Signup_Action } from "../../../Redux/Sigup/SignupActions";
import { useDispatch } from "react-redux";
import MaskedInput from "antd-mask-input";
import Axiosinstance from "../../../config";
import { getGeoInfo } from "./../../../utills/helpers";
import CustomUpload from "../../../Common/custom-upload";
import { formatinputPhoneNumber, RemoveformatPhoneNumber } from "../../../utills/helpers";

const { Option } = Select;

const initialSignUpState = {
  fullName: "",
  email: "",
  secondname: "",
  password: "",
  ExperienceCertification: "",
  confirmPassword: "",
  zip: "",
  gender: undefined,
  dob: "",
  secondname: "",
  phone: "",
  specialtiesId: undefined,
  specialtiesName: undefined,
  location: {
    coordinates: [],
  },
  address: {
    street: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
  },
  isAgreedTerms: false,
};

const SignupPage = () => {
  const [Signupform, setSignupform] = useState(initialSignUpState);
  const [Signuperrors, setSignuperrors] = useState({});
  const [conditionVisible, setconditionVisible] = useState(false);
  const [Radiocondtion, setRadiocondtion] = useState(false);
  const [CheckCondition, setCheckCondition] = useState(false);
  const [locationerror, setlocationerror] = useState();
  const [Loading, setLoading] = useState(false);
  const [SpecialitiesList, setSpecialitiesList] = useState();
  const [enable, setenable] = useState(false);
  const [spin, setspin] = useState({ ExperienceCertification: false });
  const [servicesResponse, setservicesResponse] = useState();
  const [filedata, setfiledata] = useState();
  const [coutrycode, setCoutrycode] = useState("+91");
  let [BlurCheck, setBlurCheck] = useState({
    email: false,
    secondname: false,
    fullName: false,
    dob: false,
    gender: false,
    ExperienceCertification: false,
    zip: false,
    phone: false,
    password: false,
    confirmPassword: false,
    specialtiesName: false,
    country: false,
  });
  let [errors, setErrors] = useState({});

  const history = useHistory();
  const Location = useLocation();
  const dispatch = useDispatch();

  const {
    fullName,
    zip,
    location,
    phone,
    email,
    password,
    confirmPassword,
    ExperienceCertification,
    secondname,
    dob,
    gender,
    isAgreedTerms,
    specialtiesName,
    address,
  } = Signupform;
  console.log("Signupform", Signupform);
  useEffect(() => {
    Getspecialties();
    Getservices();
    getGeoInfo().then((res) => setCoutrycode(res?.country_calling_code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      email &&
      fullName &&
      zip &&
      phone &&
      password &&
      confirmPassword &&
      dob &&
      gender &&
      ExperienceCertification &&
      location.coordinates.length > 1 &&
      isAgreedTerms &&
      secondname
    ) {
      setenable(true);
    } else {
      setenable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Signupform]);

  const Getspecialties = async () => {
    try {
      const response = await Axiosinstance.get("auth/v1.0/services");
      console.log('kjnbxchfbf', response?.data?.data[0]?._id)
      setservicesResponse(response?.data?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const Getservices = async () => {
    try {
      const response = await Axiosinstance.get("/profile/v1.0/specialties");
      setSpecialitiesList(response.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  let isValid = () => {
    let valid = true;
    //reading all controls from 'errors' state
    for (let control in errors) {
      if (errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  let validate = () => {
    let errorsData = {};
    //email can't blank
    if (!email) {
      errorsData.email = "Email can't be blank";
    }
    //email regex
    const validEmailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (email) {
      if (!validEmailRegex.test(email)) {
        errorsData.email = "Proper email address is expected";
      }
    }
    //password can't blank
    if (!password) {
      errorsData.password = "Password can't be blank";
    }
    //password regex
    const validPasswordRegex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,15})/;
    if (password) {
      if (password.length < 6 || password.length > 15) {
        errorsData.password = "Password should be between 6 and 15 characters long";
      } else if (!validPasswordRegex.test(password)) {
        errorsData.password =
          "Password should have at least one uppercase letter, one lowercase letter, and one digit";
      }
    }
    
    if (!confirmPassword) {
      errorsData.confirmPassword = " Confirm Password can't be blank";
    } else if (password !== confirmPassword) {
      errorsData.confirmPassword =
        "Password and Confirm Password should be same";
    }
    if (!phone) {
      errorsData.phone = "Phone Number can't be blank";
    }
    if (phone) {
      if (phone.length < 14) {
        errorsData.phone = "phone Number should be 10 digits ";
      }
    }
    //fullName can't blank
    if (!fullName) {
      errorsData.fullName = "First Name can't be blank";
    }
    // if (fullName) {
    //   if (fullName.length < 4) {
    //     errorsData.fullName = "Full Name can't be Short";
    //   }
    // }
    //secondname can't blank
    if (!secondname) {
      errorsData.secondname = "Last Name can't be blank";
    }
    //dateOfBirth can't blank
    if (!dob) {
      errorsData.dob = "Date of Birth can't be blank";
    }
    //gender can't blank
    if (!gender) {
      errorsData.gender = "Gender can't be blank";
    }
    if (!ExperienceCertification) {
      errorsData.ExperienceCertification =
        "Please upload Experience Certification";
    }

    if (!zip) {
      errorsData.zip = "Zip Code can't be blank";
    }
    if (zip) {
      if (coutrycode === "+1" && zip.length < 5) {
        errorsData.zip = "Zip should be 5 digits";
      }
      // else if (coutrycode !== "+1" && zip.length < 6) {
      //   errorsData.zip = "Zip  should be 6 digits";
      // }
    }
    if (!specialtiesName) {
      errorsData.specialtiesName = "Specialities can't be blank";
    }
    // if (location.geocode.coordinates.length < 1) {
    //   errorsData.country = "Please select a address";
    // }
    setErrors(errorsData);
  };

  const Routercall = (val) => {
    if (val === "/confirmregister") {
      history.push("/confirmregister");
    } else {
      message.error(val);
    }
  };

  const handleLoader = (val) => {
    setLoading(val);
  };

  const onSignup = () => {
    let BlurCheckData = BlurCheck;
    Object.keys(BlurCheck).forEach((control) => {
      BlurCheckData[control] = true;
    });
    setBlurCheck(BlurCheckData);
    if (location.coordinates.length < 1) {
      setlocationerror("Please select a address");
    }
    validate();
    if (isValid() && location.coordinates.length > 1) {
      const {
        fullName,
        zip,
        location,
        phone,
        email,
        password,
        confirmPassword,
        ExperienceCertification,
        secondname,
        dob,
        gender,
        isAgreedTerms,
        specialtiesId,
        specialtiesName,
      } = Signupform;

      let location1 = {
        coordinates: [location.coordinates[1], location.coordinates[0]],
      };

      const sigupdata = {
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        phone: RemoveformatPhoneNumber(phone),
        countryCode: coutrycode,
        fullName: `${fullName} ${secondname}`,
        profilePhoto: "",
        location: location1,
        address: address,
        zip: Number(zip),
        gender: gender,
        isAgreedTerms: isAgreedTerms,
        services: servicesResponse?.[0],
        dob: new Date(dob),
        servicesName: ["HOMEVISIT"],
        servicesID: ["61bb3bcfebfe8098313da86b"],
        specialtiesName: specialtiesName,
        specialtiesId: specialtiesId,
        medicalCertification: ExperienceCertification,
      };
      dispatch(Doctor_Signup_Action(sigupdata, Routercall, handleLoader));
    }
  };

  useEffect(validate, [Signupform]);

  const CheckonChange = (e) => {
    if (e.target.checked) {
      setCheckCondition(e.target.checked);
      setRadiocondtion(true);
      setSignupform({
        ...Signupform,
        isAgreedTerms: true,
      });
    } else {
      setCheckCondition(false);
      setRadiocondtion(false);
    }
  };

  const SignuphandleChange = (event) => {
    const checkspin = Object.values(spin).every((item) => item === false);
    const { name, value } = event.target;

    if (checkspin) {
      setSignupform({
        ...Signupform,
        [name]:
          name === "zip" || name === "NPINumber"
            ? value.match(/([0-9a-zA-Z ])/g)?.join("")
            : name === "phone" ? formatinputPhoneNumber(value) : value,
      });
    } else {
      message.warning("Upload in progress, please wait");
    }
  };

  const SignupSelectChange = (event) => {
    const servicesname = SpecialitiesList[event].name;
    const serviceId = SpecialitiesList[event]._id;
    const checkspin = Object.values(spin).every((item) => item === false);
    if (checkspin) {
      setSignupform({
        ...Signupform,
        specialtiesName: servicesname,
        specialtiesId: serviceId,
      });
    } else {
      message.warning("Upload in progress, please wait");
    }
  };

  const fileUploadRequest = ({ file, onSuccess }) => {
    setfiledata(file);
  };

  const handleupload = (info, key) => {
    setspin({
      ...spin,
      [key]: true,
    });
    console.log("info", info);
    console.log("filedata", filedata.size);
    if (filedata && filedata.size <= 5000000) {
      const newFileName = filedata?.name.replace(/\..+$/, "");
      const config = {
        bucketName: "ub-provider-image-uploader",
        dirName: `TeleHealthProvider/${email}/Medical` /* optional */,
        region: "us-west-1",
        accessKeyId: "AKIAW7CW6QVRSTEZBUOO",
        secretAccessKey: "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",
      };
      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(filedata, newFileName).then((data) => {
        if (data.status === 204) {
          setSignupform({
            ...Signupform,
            [key]: data.location,
          });
          setspin({
            ...spin,
            [key]: false,
          });
        } else {
          console.log("fail");
        }
      });
    } else {
      message.error(`${filedata.name} is too large Maximum file size is 5MB`);
      setspin({
        ...spin,
        [key]: false,
      });
    }
  };

  const gotoConditionModal = () => {
    setconditionVisible(true);
  };

  const Agree = () => {
    const errors = { ...Signuperrors };
    if (CheckCondition) {
      setconditionVisible(false);
    } else {
      errors.CheckCondition = "*Mandatory field";
      setSignuperrors(errors);
    }
  };


  const handleDobChange = (e) => {
    const checkspin = Object?.values(spin)?.every((item) => item === false);
    if (checkspin) {
      setSignupform({
        ...Signupform,
        dob: e ? moment(e._d).format("MMM-DD-YYYY") : "",
      });
    } else {
      message.warning("Upload in progress, please wait");
    }
  };

  const handlegenderChange = (event) => {
    setSignupform({
      ...Signupform,
      gender: event,
    });
  };

  const antIcon = <Icon type="loading" style={{ fontSize: 80 }} spin />;
  const areaCodes = [
    // {
    //   code: "+91",
    //   dialling_code: "+91",
    // },
    {
      code: "+1",
      dialling_code: "+1",
    },
  ];
  const handleCoutrycode = (val) => {
    setCoutrycode(val);
  };

  const buildAreaCode = () => {
    return (
      <Select value={coutrycode} onChange={handleCoutrycode}>
        {areaCodes.map((e) => {
          return (
            <Option value={e.dialling_code} key={e.code}>
              {e.dialling_code}
            </Option>
          );
        })}
      </Select>
    );
  };

  return (
    <div>
      <div className="Signup-Container"
        style={{ opacity: Loading && "0.5" }}
      >
        <div className="HeaderlogoContainer">
          <Link to="/">
            <img src={header_logo} alt="Logo" on />
          </Link>
        </div>
        <div className="signup-modal-body">
          <div className="signupmodal-Content">
            <h1>Become a CareGiver Provider</h1>
            <p className="signupmodal-info">
              Lets set up your profile with some basic information
            </p>
            <div className="signup-flelds-inputs">
              <div className="Fields-Inputs">
                <CustomInput
                  className="InputCustom"
                  value={fullName}
                  placeholder="First Name"
                  name="fullName"
                  onChange={SignuphandleChange}
                  errorText={BlurCheck["fullName"] && errors.fullName}
                  onBlur={(event) => {
                    setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                    validate();
                  }}
                />
                <Spin
                  className="SignupLoader"
                  spinning={Loading}
                  indicator={antIcon}
                />
                <CustomInput
                  className="InputCustom"
                  value={secondname}
                  placeholder="Last Name"
                  name="secondname"
                  onChange={SignuphandleChange}
                  errorText={BlurCheck["secondname"] && errors.secondname}
                  onBlur={(event) => {
                    setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                    validate();
                  }}
                />
              </div>
              <div className="Fields-Inputs1">
                <CustomInput
                  className="InputCustom"
                  value={email}
                  placeholder="Email"
                  name="email"
                  onChange={SignuphandleChange}
                  errorText={BlurCheck["email"] && errors.email}
                  onBlur={(event) => {
                    setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                    validate();
                  }}
                />

                <div className="Input-Details">
                  <Input.Password
                    className="InputCustom"
                    placeholder="Password"
                    value={password}
                    name="password"
                    onChange={SignuphandleChange}
                    onBlur={(event) => {
                      setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                      validate();
                    }}
                  />
                  {BlurCheck["password"] && errors.password && (
                    <div className="ERROR">
                      {BlurCheck["password"] && errors.password}
                    </div>
                  )}
                  {!(BlurCheck["password"] && errors.password) && (
                    <p className="NO-ERROR" />
                  )}
                </div>
              </div>

              <div className="Fields-Inputs2">
                <div className="Input-Details">
                  <Input.Password
                    className="InputCustom"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    name="confirmPassword"
                    onChange={SignuphandleChange}
                    onBlur={(event) => {
                      setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                      validate();
                    }}
                  />
                  {BlurCheck["confirmPassword"] && errors.confirmPassword && (
                    <div className="ERROR">
                      {BlurCheck["confirmPassword"] && errors.confirmPassword}
                    </div>
                  )}
                  {!(
                    BlurCheck["confirmPassword"] && errors.confirmPassword
                  ) && <p className="NO-ERROR" />}
                </div>

                <div className="Input-Details">
                  <DatePicker
                    placeholder="Date of Birth"
                    format="MMM-DD-YYYY"
                    onChange={handleDobChange}

                    onBlur={(event) => {
                      setBlurCheck({ ...BlurCheck, dob: true });
                      validate();
                    }}
                  />
                  <p className="ERROR">{BlurCheck["dob"] && errors.dob}</p>
                </div>
              </div>

              <div className="Fields-Inputs3">
                <div className="Input-Details">
                  <AutoComplete
                    Addressdata={Signupform}
                    setSignupform={setSignupform}
                    errorText={Signuperrors.address}
                    setlocationerror={setlocationerror}
                  />
                  <p className="ERROR">{locationerror && locationerror}</p>
                </div>
                <div className="Input-Details">
                  <MaskedInput
                    mask={coutrycode === "+1" ? "11111" : "11111"}
                    placeholder="Zip Code"
                    // className="InputCustom"
                    name="zip"
                    onBlur={(event) => {
                      setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                      validate();
                    }}
                    errorText={BlurCheck["zip"] && errors.zip}
                    value={zip}
                    onChange={SignuphandleChange}
                  />
                  {BlurCheck["zip"] && errors.zip && (
                    <p className="ERROR">{BlurCheck["zip"] && errors.zip}</p>
                  )}
                </div>
              </div>
              <div className="Fields-Inputs4">
                <div className="Input-Details">
                  <Select
                    placeholder="Select Specialities"
                    value={specialtiesName}
                    onBlur={(event) => {
                      setBlurCheck({ ...BlurCheck, specialtiesName: true });
                      validate();
                    }}
                    onChange={SignupSelectChange}
                  >
                    {SpecialitiesList?.map((item, i) => (
                      <Option value={i}>{item.name}</Option>
                    ))}
                  </Select>
                  <p className="ERROR">
                    {BlurCheck["specialtiesName"] && errors.specialtiesName}
                  </p>
                </div>
                <div className="Input-Details">
                  {/* <div className="Input-Details "> */}
                  <CustomInput
                    className="ant-select-contrycode"
                    value={phone}
                    placeholder="Contact Number"
                    name="phone"
                    addonBefore={"+1"}
                    onBlur={(event) => {
                      setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                      validate();
                    }}
                    onChange={SignuphandleChange}
                  />
                  {BlurCheck["phone"] && errors.phone && (
                    <p className="ERROR">
                      {BlurCheck["phone"] && errors.phone}
                    </p>
                  )}
                  {/* </div> */}
                </div>
              </div>
              <div className="Fields-Inputs">
                <div className="Input-Details">
                  <Select
                    placeholder="Gender"
                    value={gender}
                    onBlur={(event) => {
                      setBlurCheck({ ...BlurCheck, gender: true });
                      validate();
                    }}
                    onChange={handlegenderChange}
                  >
                    <Option value="MALE">Male</Option>
                    <Option value="FEMALE">Female</Option>
                  </Select>
                  <p className="ERROR">
                    {BlurCheck["gender"] && errors.gender}
                  </p>
                </div>
                <div className="Input-Details">
                  <CustomInput
                    value={ExperienceCertification.substring(
                      ExperienceCertification.lastIndexOf("/") + 1
                    )}
                    onBlur={(event) => {
                      setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                      validate();
                    }}
                    placeholder="Experience Certification"
                    name="ExperienceCertification"
                    suffix={
                      !spin.MedicalCertification ? (
                        <CustomUpload
                          setUploadstate={setSignupform}
                          setspin={setspin}
                          accept={
                            ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          }
                          uploadfrom={"DoctorSignup"}
                          uploadkey={"ExperienceCertification"}
                          email={email}
                        />
                      ) : (
                        <Spin spinning={spin.MedicalCertification} />
                      )
                    }
                    errorText={
                      BlurCheck["ExperienceCertification"] &&
                      errors.ExperienceCertification
                    }
                  />
                </div>

              </div>

              <div className="signup-agree">
                <Radio onClick={gotoConditionModal} checked={Radiocondtion}>
                  <span className="signup-agree-black">
                    I agree to the &nbsp;
                    <span className="signup-agree-active">
                      Conditions of Use
                    </span>
                    <span className="signup-agree-active">Privacy Policy</span>,
                    &
                    <span className="signup-agree-active">
                      &nbsp;Informed Consent
                    </span>
                  </span>
                </Radio>
                {Signuperrors && (
                  <p style={{ color: "red", paddingTop: "1rem" }}>
                    {Signuperrors.Radiocondtion}
                  </p>
                )}
              </div>
            </div>

            <div className="signup-agree-button">
              <Button
                onClick={onSignup}
                disabled={false}
                className="signup-agree-button-setup"
              //style={{ opacity: enable ? 1 : 0.5 }}
              >
                Register
              </Button>
            </div>
            {/* <div className="sign-account-check">
                  <span>
                    Already have an account?&nbsp;
                    <span className="active-color" onClick={()=>history.push('/login') }>Login</span>
                  </span>
                </div> */}
          </div>
        </div>

        <Modal
          className="ConditionModal"
          centered
          footer={null}
          visible={conditionVisible}
          closable={false}
          onCancel={() => setconditionVisible(false)}
        >
          <div className="ConditionModal-Content">
            <div className="HeaderImage">
              <img
                src={require("../../../Assets/header_logo.png")}
                alt="headerImg"
              />
            </div>
            <h3>CONSENT to WEBSITE APP</h3>
            <p>
              By signing below, you acknowledge that you understand and agree
              with the following:
            </p>
            <p>I understand that:</p>
            <div className="ConditionsContent">
              <div className="Conditions-SubContent">
                <p>1.&nbsp;</p>
                <p>
                  UBORA HEALTH Website App involves the use of online access to
                  all healthcare services.
                </p>
              </div>
              <div className="Conditions-SubContent">
                <p>2.</p>
                <p>
                  By using the UBORA HEALTH Website App “SITE”, I agree to be
                  bound by these Terms of Service and to use the SITE in
                  accordance with these Terms of Service, our Privacy Policy,
                  and any additional terms and conditions that are referenced
                  herein or that otherwise may apply to specific sections of the
                  SITE, or to products and services that we make available to
                  you through the SITE (all of which are deemed part of these
                  Terms of Service).
                </p>
              </div>
              <div className="Conditions-SubContent">
                <p>3.</p>
                <p>
                  In order to access certain features of the SITE, I agree to
                  provide certain demographic and payment information. UBORA
                  HEALTH has the right to suspend or terminate your account and
                  refuse any and all current or future use of the SITE if there
                  are reasonable grounds to suspect that such information is
                  untrue, inaccurate, or incomplete.
                </p>
              </div>
              <div className="Conditions-SubContent">
                <p>4.</p>
                <p>
                  I am responsible for maintaining the confidentiality of my
                  UBORA HEALTH Website App password and account.
                </p>
              </div>
              <div className="Conditions-SubContent">
                <p>5.</p>
                <p>
                  UBORA HEALTH arranges for the access to healthcare services
                  you may avail, UBORA HEALTH Website App does not directly
                  provide for these services.
                </p>
              </div>
              <div className="Conditions-SubContent">
                <p>6.</p>
                <p>
                  The laws that protect privacy and the confidentiality of
                  medical information - Health Insurance Portability &
                  Accountability Act of 1996 (HIPAA) apply to UBORA HEALTH
                  Website App.
                </p>
              </div>
              <div className="Conditions-SubContent">
                <p>7.</p>
                <p>
                  I hereby authorize UBORA HEALTH to release or disclose
                  information in relation to the services that I will avail.
                </p>
              </div>
              <div className="Conditions-SubContent">
                <p>8.</p>
                <p>
                  This consent will be governed by and construed in accordance
                  with the laws of the State of California.
                </p>
              </div>
              <div className="Conditions-SubContent">
                <p>9.</p>
                <p>
                  I have the right to withhold or withdraw my consent to the use
                  of UBORA HEALTH Website App at any time.
                </p>
              </div>
            </div>
            <Checkbox
              className="CheckboxContent"
              checked={CheckCondition}
              onChange={CheckonChange}
            >
              I have read this document carefully, and understand the benefits
              of the UBORA HEALTH Website App and have had my questions
              regarding the Website App explained and I hereby give my consent
              to use UBORA HEALTH Website App under the terms described herein.
            </Checkbox>
            {Signuperrors && (
              <p style={{ color: "red", paddingTop: "1rem" }}>
                {Signuperrors.CheckCondition}
              </p>
            )}
            <div className="Agree">
              <Button className="AgreeBtn" onClick={Agree} type="primary">
                Agree
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default SignupPage;
