import { Row, Col, Button, message, Icon, Spin } from "antd";
import React, { useState, useEffect } from "react";
import "./Accountinfo.scss";
import { useDispatch, useSelector } from "react-redux";
import EditEducation from "./Components/EditEducation";
import EditAccomplishment from "./Components/EditAccomplishment";
import PersonalDetails from "./Components/PersonalDetails";
import EditPersonalinfo from "./Components/EditPersonalinfo";
import EditProfessionalinfo from "./Components/EditProfessionalinfo";
import {
  Profile_fetch_Action,
  Profile_Update_Action,
} from "../../../../Redux/Profile/ProfileAction";
import { useHistory, Link } from "react-router-dom";
import Axiosinstance from "../../../../config";
import DashboardLayout from "./../../DashboardLayout";
import { profilePhotoSetter } from "../../../../Common/setLocalStorage/setLocalStorage";
import GetlocalStorage from "../../../../Common/setLocalStorage/GetlocalStorage";

const Accountinfo = (props) => {
  const [ProfileInfo, setProfileInfo] = useState();
  const [SpecialitiesResponse, setSpecialitiesResponse] = useState();
  const [EditResponse, setEditResponse] = useState({});
  const [AddFellowshiptext, setAddFellowshiptext] = useState();
  const [AddInternshiptext, setAddInternshiptext] = useState();
  const [AddProtext, setAddProtext] = useState();
  const [AddAwardstext, setAwardstext] = useState();
  const [AddCertifile, setAddCertifile] = useState();
  const [Removedinternship, setRemovedinternship] = useState([]);
  const [RemovedFellowship, setRemovedFellowship] = useState([]);
  const [RemovedProMembership, setRemovedProMembership] = useState([]);
  const [RemovedAwards, setRemovedAwards] = useState([]);
  const [initialResponse, setinitialResponse] = useState();
  const [Loading, setLoading] = useState(false);
  let [errors, setErrors] = useState({});
  let [BlurCheck, setBlurCheck] = useState({
    fullName: false,
    zip: false,
    specialities: false,
    degree: false,
    summery: false,
    profileHeader: false,
    languages: false,
    SpecialitiesList: false,
    lincense: false,
    medicalSchool: false,
    AddInternshiptext: false,
    AddFellowshiptext: false,
    residency: false,
    AddProtext: false,
    AddAwardstext: false,
    AddCertifile: false,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const ProfileData = useSelector((state) => state.ProfileReducer);
  let getlocalStorage = GetlocalStorage();
  let CareGiverid = getlocalStorage.CareGiverid;
  const spid = getlocalStorage.specialtiesId;

  const Getsubspecialties = async () => {
    try {
      const res = await Axiosinstance.get(
        `profile/v1.0/subspecialties/${CareGiverid}/${spid}`,
      );

      if (res.status === 200) {
        setSpecialitiesResponse(res.data.data);
      } else if (res.status === 401) {
        history.push("/");
      }
    } catch (error) {
      console.info("error", error);
    }
  };

  useEffect(() => {
    Getsubspecialties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routercall = () => {
    history.push("/");
  };

  const handleLoader = (val) => {
    setLoading(val);
  };

  useEffect(() => {
    setProfileInfo(ProfileData);
    setinitialResponse(ProfileData);

  }, [ProfileData]);

  useEffect(() => {
    dispatch(Profile_fetch_Action(CareGiverid, routercall, handleLoader));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let isValid = () => {
    let valid = true;
    //reading all controls from 'errors' state
    for (let control in errors) {
      if (errors[control].length > 0) {
        valid = false;
      }
    }
    return valid;
  };

  console.log("EditResponse", EditResponse);

  let validate = () => {
    let errorsData = {};
    const { fullName, dob, zip, location } = ProfileInfo ?? [];
    const {
      profileHeader,
      degree,
      license,
      subSpecialtiesName,
      languages,
      summery,
    } = ProfileInfo ?? [];

    const { medicalSchool, internship, fellowship, residency } =
      ProfileInfo ?? {};
    const { certification, profMembership, awards } = ProfileInfo ?? [];

    if (!fullName) {
      errorsData.fullName = "Full Name can't be blank";
    }
    if (fullName) {
      if (fullName.length < 4) {
        errorsData.fullName = "Full Name can't be Short";
      }
    }
    //dateOfBirth can't blank
    if (!dob) {
      errorsData.dob = "Date of Birth can't be blank";
    }
    if (!zip) {
      errorsData.zip = "Zip code can't be blank";
    } else if (!/^\d{5}$/.test(zip)) {
      errorsData.zip = "Zip code should be exactly 5 digits";
    }
    if (location?.coordinates.length < 1) {
      errorsData.country = "Please select a address";
    }
    if (!profileHeader) {
      errorsData.profileHeader = "Profile Header can't be blank";
    }
    if (profileHeader) {
      if (profileHeader.length < 4)
        errorsData.profileHeader = "Profile Header can't be Short";
    }
    if (subSpecialtiesName?.length === 0) {
      errorsData.SpecialitiesList = "other Specialities can't be blank";
    }
    if (!degree) {
      errorsData.degree = "Degree can't be blank";
    }
    if (languages?.length === 0) {
      errorsData.languages = "Languages can't be blank";
    }
    if (!license) {
      errorsData.lincense = "License can't be blank";
    }
    // if (!summery) {
    //   errorsData.summery = "summary can't be blank";
    // }
    if (!medicalSchool) {
      errorsData.medicalSchool = "Medical School can't be blank";
    }
    if (!AddInternshiptext && internship?.length === 0) {
      errorsData.AddInternshiptext = "Internship can't be blank";
    }
    if (AddInternshiptext) {
      if (!internship?.includes(AddInternshiptext)) {
        errorsData.AddInternshiptext = "please add internship in list";
      }
    }
    if (!residency) {
      errorsData.residency = "Residency can't be blank";
    }
    // if (!AddFellowshiptext && fellowship?.length === 0) {
    //   errorsData.AddFellowshiptext = "Fellowship can't be blank";
    // }
    // if (AddFellowshiptext) {
    //   if (!fellowship?.includes(AddFellowshiptext)) {
    //     errorsData.AddFellowshiptext = "please add Fellowship in list";
    //   }
    // }
    if (!AddCertifile && certification?.length === 0) {
      errorsData.AddCertifile = "Certifications can't be blank";
    }
    if (AddCertifile) {
      if (!certification?.includes(AddCertifile)) {
        errorsData.AddCertifile = "please add Certifications in list";
      }
    }
    // if (!AddProtext && profMembership?.length === 0) {
    //   errorsData.AddProtext = "ProMemberships can't be blank";
    // }
    // if (AddProtext) {
    //   if (!profMembership?.includes(AddProtext)) {
    //     errorsData.AddProtext = "please add ProMemberships in list";
    //   }
    // }
    // if (!AddAwardstext && awards?.length === 0) {
    //   errorsData.AddAwardstext = "Awards can't be blank";
    // }
    // if (AddAwardstext) {
    //   if (!awards?.includes(AddAwardstext)) {
    //     errorsData.AddAwardstext = "please add Awards in list";
    //   }
    // }
    setErrors(errorsData);
  };

  useEffect(validate, [ProfileInfo]);

  const handleUpdateLoader = (val) => {
    setLoading(val);
    if (!val) {
      message.success("updates successfully done");
    }
  };

  const handleUpdateprofile = () => {
    let BlurCheckData = BlurCheck;
    Object.keys(BlurCheck).forEach((control) => {
      BlurCheckData[control] = true;
    });
    setBlurCheck(BlurCheckData);
    validate();
    if (
      isValid() &&
      Removedinternship.length === 0 &&
      RemovedFellowship.length === 0 &&
      RemovedProMembership.length === 0 &&
      RemovedAwards.length === 0
    ) {
      if (Object.keys(EditResponse).length > 0) {
        dispatch(
          Profile_Update_Action(
            CareGiverid,
            EditResponse,
            handleUpdateLoader,
            routercall,
          ),
        );
        if (EditResponse.profilePhoto) { 
          profilePhotoSetter(EditResponse.profilePhoto)
        }
      } else {
        message.info("No updates found");
      }
    }
  };

  const antIcon = <Icon type="loading" spin />;

  return (
    <DashboardLayout>
      <div
        className="DashboardSettings-container"
        style={{ opacity: Loading && "0.5" }}
      >
        <Spin spinning={Loading} indicator={antIcon} />
        <div className="DashboardSettings-body">
          <Link to="/dashboardsettings">
            <h3>
              <Icon type="arrow-left" /> {" Account Information"}
            </h3>
          </Link>
          <div className="Accountinfo-Container">
            <Row>
              <Col lg={24}>
                <PersonalDetails
                  ProfileInfo={ProfileInfo}
                  setProfileInfo={setProfileInfo}
                  setEditResponse={setEditResponse}
                  EditResponse={EditResponse}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={24}>
                <div className="Accountinfo-Edit-Details">
                  <div className="Edit-Personal-Professionalinfo">
                    <EditPersonalinfo
                      ProfileInfo={ProfileInfo}
                      setProfileInfo={setProfileInfo}
                      setBlurCheck={setBlurCheck}
                      BlurCheck={BlurCheck}
                      validate={validate}
                      errors={errors}
                      setEditResponse={setEditResponse}
                      EditResponse={EditResponse}
                    />
                  </div>

                  <div className="Edit-Personal-Professionalinfo">
                    <EditProfessionalinfo
                      Informationdata={ProfileInfo}
                      setInformation={setProfileInfo}
                      setEditResponse={setEditResponse}
                      SpecialitiesResponse={SpecialitiesResponse}
                      initialResponse={initialResponse}
                      EditResponse={EditResponse}
                      setBlurCheck={setBlurCheck}
                      BlurCheck={BlurCheck}
                      validate={validate}
                      errors={errors}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={18}>
                <div className="Accountinfo-Edit-CareerDetails">
                  <div className="Education-Accomplishments">
                    <EditEducation
                      Educationdata={ProfileInfo}
                      setEducation={setProfileInfo}
                      setBlurCheck={setBlurCheck}
                      BlurCheck={BlurCheck}
                      validate={validate}
                      errors={errors}
                      AddFellowshiptext={AddFellowshiptext}
                      AddInternshiptext={AddInternshiptext}
                      setAddFellowshiptext={setAddFellowshiptext}
                      setAddInternshiptext={setAddInternshiptext}
                      setEditResponse={setEditResponse}
                      EditResponse={EditResponse}
                      Removedinternship={Removedinternship}
                      setRemovedinternship={setRemovedinternship}
                      RemovedFellowship={RemovedFellowship}
                      setRemovedFellowship={setRemovedFellowship}
                    />
                  </div>
                  <div className="Education-Accomplishments">
                    <EditAccomplishment
                      Accomplishmentdata={ProfileInfo}
                      setAccomplishment={setProfileInfo}
                      setAwardstext={setAwardstext}
                      AddProtext={AddProtext}
                      AddAwardstext={AddAwardstext}
                      setAddProtext={setAddProtext}
                      AddCertifile={AddCertifile}
                      setAddCertifile={setAddCertifile}
                      setBlurCheck={setBlurCheck}
                      BlurCheck={BlurCheck}
                      validate={validate}
                      errors={errors}
                      setEditResponse={setEditResponse}
                      EditResponse={EditResponse}
                      setRemovedProMembership={setRemovedProMembership}
                      RemovedProMembership={RemovedProMembership}
                      setRemovedAwards={setRemovedAwards}
                      RemovedAwards={RemovedAwards}
                    />
                  </div>
                  <div className="Education-Accomplishments-BTN">
                    <Button
                      className="UpdateSaveBtn"
                      type="primary"
                      onClick={handleUpdateprofile}
                    >
                      Update & Save
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Accountinfo;
