import React from "react";
import "./DashboardSideContent.scss";
import { Badge, Icon, Avatar } from "antd";
import { useHistory, Link } from "react-router-dom";
import GetlocalStorage from "../../../../Common/setLocalStorage/GetlocalStorage";

const DashboardSideContent = () => {
    const history = useHistory();

    let getlocalStorage = GetlocalStorage();
    const fullName=getlocalStorage.fullName;
    const specialtiesName=getlocalStorage.specialtiesName;
    const profilePhoto= getlocalStorage.profilePhoto;

    return (
      <div className="Dashboard-SideContent">
         <div className="Dashboard-SideContent-DoctorInfo">
            <div className="DoctorInfo-Icons">
              {/* <Badge offset={[-10,8]} color={'#B0779A'} dot>
                <Icon onClick={()=> history.push('/dashboardsettings/notifications')} type="bell" theme="filled" />
              </Badge> */}
            </div>
            
            <div className="DoctorInfo-Details">
              <Link to="/dashboardsettings/accountinfo">
                {profilePhoto !== "null" && profilePhoto !== "undefined" ? 
                  <Avatar size={114} src={profilePhoto} />:
                  <Avatar className="NoProfileImg" size={114}>
                    {fullName && fullName?.replace(/[^a-zA-Z0-9]/g, '').charAt(0)}
                  </Avatar>
                }
                <h1>{fullName}</h1>
                <p>{specialtiesName}</p>
              </Link>
            </div>  
         </div> 
      </div> 
    ); 
 };
 
 export default DashboardSideContent;