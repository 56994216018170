import { Select, DatePicker } from "antd";
import React from "react";
import moment from "moment";
import CustomInput from "./../../../../../Common/custom-input";
import AutoLocation from "../../../../../Common/AutoLocation";
import { formatDisplayPhoneNumber, formatinputPhoneNumber } from "../../../../../utills/helpers";

const { Option } = Select;
const dateFormat = "MMM-DD-YYYY";

const EditPersonalinfo = ({
  setProfileInfo,
  ProfileInfo,
  setEditResponse,
  EditResponse,
  setBlurCheck,
  BlurCheck,
  errors,
  validate,
}) => {

  // const address =localStorage.getItem("signinAdress")

   const formatDisplayPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';

    // Remove all non-numeric characters
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // Check if the cleaned number has the correct length
    if (cleaned.length !== 10) {
      return phoneNumber; // Return the number as-is if it doesn't have 10 digits
    }

    // Format the cleaned number
    const formatted = `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6)}`;

    return formatted;
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Handle zip code differently
    if (name === "zip") {
      const zipValue = Number(value);
      setEditResponse({
        ...EditResponse,
        [name]: zipValue,
        location: {
          ...EditResponse.location,
          address: {
            zipCode: zipValue,
          },
        },
      });
      setProfileInfo({
        ...ProfileInfo,
        [name]: zipValue,
      });
    } else {
      // Handle phone number separately to ensure formatting
      const updatedValue = name === "phone" ? value : value;
      setProfileInfo({
        ...ProfileInfo,
        [name]: updatedValue,
      });
      setEditResponse({
        ...EditResponse,
        [name]: updatedValue,
      });
    }
  };


  const handleDobChange = (e) => {
    setProfileInfo({
      ...ProfileInfo,
      dob: e._d.toString(),
    });
    setEditResponse({
      ...EditResponse,
      dob: e._d.toString(),
    });
  };

  const {
    fullName,
    email,
    dob,
    phone,
    servicesName,
    NPINumber,
    specialtiesName,
    zip,
  } = ProfileInfo ?? [];
  const dateofbirth = moment(ProfileInfo?.dob).format('MMM-DD-YYYY');

  console.log("dateofbirth", dateofbirth);

  return (
    <>
      <div className="editProfileInfo_content_block">
        <h2>Personal Information</h2>
        <div className="editProfile_content">
          <div className="personal_info">
            <h5>Full name</h5>
            <CustomInput
              className="InputCustom"
              value={fullName}
              name="fullName"
              disabled={true}
              onChange={handleInputChange}
              errorText={BlurCheck["fullName"] && errors.fullName}
              onBlur={(event) => {
                setBlurCheck({ ...BlurCheck, [event.target.name]: true });
                validate();
              }}
            />
          </div>
          <div className="personal_info">
            <h5>Mode</h5>
            <Select
              placeholder="services"
              className="MultiSelect"
              mode="multiple"
              value={servicesName && servicesName}
              disabled={true}
            >
              {servicesName?.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
          <div className="personal_info">
            <h5>Date Of Birth</h5>
            <div className="BirthdayInput">
              <DatePicker
                value={moment(dateofbirth, dateFormat)}
                format={dateFormat}
                onChange={handleDobChange}
                onBlur={(event) => {
                  setBlurCheck({ ...BlurCheck, dob: true });
                  validate();
                }}
                disabled={true}
              />
            </div>
          </div>
          {BlurCheck["dob"] && errors.dob && (
            <p className="ERROR">{BlurCheck["dob"] && errors.dob}</p>
          )}
          <div className="personal_info">
            <h5>Phone Number</h5>
            <CustomInput
              className="InputCustom"
              value={formatDisplayPhoneNumber(phone)} // Format phone number for display
              name="phone"
              onChange={handleInputChange}
              addonBefore={"+1"}
            />


          </div>
          <div className="personal_info">
            <h5>Email </h5>
            <CustomInput
              className="InputCustom"
              value={email}
              name="email"
              disabled={true}
            />
          </div>
          <div className="personal_info">
            <h5>Speciality</h5>
            <CustomInput
              className="InputCustom"
              value={specialtiesName}
              name="specialtiesName"
              disabled={true}
            />
          </div>
          <div className="personal_info">
            <h5>Location </h5>
            <div className="Location-Details">
              <AutoLocation
                Addressdata={ProfileInfo}
                setProfileInfo={setProfileInfo}
                setEditResponse={setEditResponse}
                EditResponse={EditResponse}
              />
            </div>
          </div>
          {errors && errors.country && (
            <p className="ERROR">{errors && errors.country}</p>
          )}

          <div className="personal_info">
            <h5>Zipcode</h5>
            <CustomInput
              className="InputCustom"
              type="number"
              value={zip}
              name="zip"
              errorText={BlurCheck["zip"] && errors.zip}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPersonalinfo;
